<div class="card">
    <div class="card-header">
        {{pageTitle}}
    </div>
    <div class="card-body">
        <div class="container-fluid">
            <div class="text-center">
                <img src="./assets/images/CohLogo.jpg"
                     class="img-responsive center-block"
                     style="max-height: 275px;padding-bottom:50px" alt="COH Logo"/>
            </div>

            <div class="text-center"></div>
            <div class="text-center">
                <h3></h3>
            </div>
            <div class="text-center">

                <div *ngIf="hasValidToken">
                    <transaction-summary-dashboard></transaction-summary-dashboard>
                </div>
            </div>
        </div>
    </div>
</div>
