<div *ngIf="data">
    <div class="card"> <!-- start Experian Text Response-->
        <div class="card-header">
            Experian Text Response
        </div>

        <div class="card-body">

            <div class="row">
                <code class="col-md-12">{{data.experianResponseText}}</code>
            </div>

        </div> <!-- end Eligibility Benefit Response-->

    </div>
</div>