import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EligibilitySummaryComponent} from './eligibility-summary/eligibility-summary.component';
import {
    EligibilitySummaryHistoricalComponent
} from "./eligibility-summary-historical/eligibility-summary-historical.component";
import {RouterModule} from '@angular/router';
import {DailyTransactionModule} from "../experian-transactions/daily-transaction.module";

import {ResponseValidationComponent} from "./response-validation/response-validation.component";
import {BenefitMemberComponent} from "../view-parts/benefit-member/benefit-member.component";

import {EligibilityQueryComponent} from "./eligibility-query/eligibility-query.component";
import {EligibilityQueryV2Component} from "./eligibility-queryv2/eligibility-queryV2.component"
import {
    EligibilityBenefitResponseComponent
} from "./eligibility-benefit-response/eligibility-benefit-response.component";
import { EligibilityBenefitInformationComponent } from './eligibility-benefit-information/eligibility-benefit-information.component';
import {ExperianLookupComponent} from "../view-parts/experian-lookup/experian-lookup.component";
import { EligibilityBenefitAdditionalInformationComponent } from './eligibility-benefit-addtional-information/eligibility-benefit-additional-information.component';
import {QualifiedDateComponent} from "../view-parts/qualified-date/qualified-date.component";
import {QualifiedDateRangeComponent} from "../view-parts/qualified-date-range/qualified-date-range.component";
import {IdentificationComponent} from "../view-parts/identification/identification.component";
import {ProcedureComponent} from "../view-parts/procedure/procedure.component";
import {QualifiedAmountComponent} from "../view-parts/qualified-amount/qualified-amount.component";
import { EligibilityRelatedEntityComponent } from './eligibility-related-entity/eligibility-related-entity.component';
import {NameComponent} from "../view-parts/name/name.component";
import {EntityTypeComponent} from "../view-parts/entity-type/entity-type.component";
import {PostalAddressComponent} from "../view-parts/postal-address/postal-address.component";
import {EntityContactComponent} from "../view-parts/entity-contact/entity-contact.component";
import {ProviderInformationComponent} from "../view-parts/provider-information/provider-information.component";
import {RequestValidationComponent} from "../view-parts/request-validation/request-validation.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTableModule} from "@angular/material/table";
import { MatInputModule } from '@angular/material/input';
import { EligibilityBenefitsSourceComponent } from './eligibility-benefits-source/eligibility-benefits-source.component';
import { EligibilityBenefitsReceiverComponent } from './eligibility-benefits-receiver/eligibility-benefits-receiver.component';
import {AuthGuardWithForcedLogin} from "../core/auth-guard-with-forced-login.service";
import { ExperianTextResponseComponent } from './eligibility-experian-text-response/eligibility-experian-text-response.component';


@NgModule({
    declarations: [
        EligibilitySummaryComponent,
        EligibilitySummaryHistoricalComponent,
        ResponseValidationComponent,
        BenefitMemberComponent,
        EligibilityQueryComponent,
        EligibilityQueryV2Component,
        EligibilityBenefitResponseComponent,
        EligibilityBenefitInformationComponent,
        ExperianLookupComponent,
        EligibilityBenefitAdditionalInformationComponent,
        QualifiedDateComponent,
        QualifiedDateRangeComponent,
        IdentificationComponent,
        ProcedureComponent,
        QualifiedAmountComponent,
        EligibilityRelatedEntityComponent,
        NameComponent,
        EntityTypeComponent,
        PostalAddressComponent,
        EntityContactComponent,
        ProviderInformationComponent,
        RequestValidationComponent,
        EligibilityBenefitsSourceComponent,
        EligibilityBenefitsReceiverComponent,
        ExperianTextResponseComponent
    ],
    imports: [
        RouterModule.forChild([
            {
                path: 'eligibilitysummary/v1/geteligibilitysummary/:id',
                component: EligibilitySummaryComponent,
                canActivate: [AuthGuardWithForcedLogin]
            },
            {
                path: 'eligibilitySummaryHistorical/:id',
                component: EligibilitySummaryHistoricalComponent,
                canActivate: [AuthGuardWithForcedLogin]
            },
        ]),
        CommonModule,
        DailyTransactionModule,
        MatFormFieldModule,
        MatTableModule,
        MatInputModule

    ]
})
export class EligibilityModule {
}
