import {Component, Input, OnInit} from '@angular/core';
import { EligibilityResponseV2 } from 'src/app/Models/EligibilityResponse';

@Component({
  selector: 'vp-eligibility-experian-text-response',
  templateUrl: './eligibility-experian-text-response.component.html',
  styleUrls: ['./eligibility-experian-text-response.component.css']
})
export class ExperianTextResponseComponent implements OnInit {

  constructor() { }


  @Input("eligibilityResponseV2") data! : EligibilityResponseV2


  ngOnInit(): void {
  }

}