import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {DataService} from "../../shared/data.service";
import {ActivatedRoute} from "@angular/router";
import {AppError} from "../../Models/AppError";
import {HttpUrlEncodingCodec} from "@angular/common/http";
import {IEligibilityResponse, EligibilityResponse, EligibilityResponseV2} from "../../Models/EligibilityResponse";
import {EligibilityQueryV2} from "../../Models/EligibilityQueryV2";
import {EligibilityQuery, IEligibilityQuery} from "../../Models/EligibilityQuery";


@Component({
    selector: 'app-eligibility-summary',
    templateUrl: './eligibility-summary.component.html',
    styleUrls: ['./eligibility-summary.component.css']
})

export class EligibilitySummaryComponent implements OnInit {

    pageTitle = 'Eligibility Summary';
    errorMessage = '';
    searchId!: string;
    data!: IEligibilityResponse;
    dataV2!: EligibilityResponseV2;
    private dataSub!: Subscription;
    HttpUrlEncodingCodec = new HttpUrlEncodingCodec();


    constructor(private dataService: DataService, private route: ActivatedRoute) {

    }


    ngOnInit(): void {
        console.log("EligibilitySummaryComponent snapshot: " + this.route.snapshot.toString());
        console.log("EligibilitySummaryComponent route: " + this.route.toString());

        let id = this.route.snapshot.paramMap.get('id');
        if (id != null) {
            this.searchId = this.HttpUrlEncodingCodec.decodeValue(id);
            this.fetchData(this.searchId);
        }
    };

    search(event: Event) {
        if (event.target) {
            this.searchId  = (event.target as HTMLInputElement)?.value;
            this.fetchData(this.searchId );
        }
    };

    // In your component
    isVersion2(source:any){
       return source.hasOwnProperty("experianResponseText")
    }

    CastToVersion2(query:IEligibilityQuery){
        return query as EligibilityQueryV2;
    }

    CastToVersion1(query:IEligibilityQuery){
        return query as EligibilityQuery;
    }

    CastDataToVersion2(data:IEligibilityResponse){
        return data as EligibilityResponseV2;
    }

    ngOnDestroy(): void {
        this.dataSub.unsubscribe();
    }

    private fetchData(id: string): void {
        this.errorMessage = '';
        this.data;

        this.dataSub = this.dataService.getEligibilitySummary(id)
                .subscribe({
                next:
                (responseData: any | AppError) => {
                    if(this.isVersion2(responseData)){
                        this.data = <EligibilityResponseV2> responseData;
                        this.dataV2 = <EligibilityResponseV2> responseData;
                    }else{
                        this.data = <EligibilityResponse> responseData;
                    }
                },
                error: (err: AppError) => {
                    console.error('EligibilitySummaryComponent Observer got an error: ' + JSON.stringify(err));
                    this.errorMessage = err.friendlyMessage;
                },
                complete: () => {
                    console.log('EligibilitySummaryComponent Observer got a complete notification');
                }
            });
    }


    protected readonly EligibilityResponse = EligibilityResponse;
}
