import { HttpClient, HttpEventType } from "@angular/common/http";
import { Component, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, finalize } from "rxjs";
import { DataService } from "../../shared/data.service";
import { AppError } from "src/app/Models/AppError";
import { AuthService } from "../auth.service";

@Component({
  selector: "experian-payers-upload",
  templateUrl: "experian-payers-upload.component.html",
  styleUrls: ["experian-payers-upload.component.css"],
})
export class ExperianPayersUploadComponent {
  get identityClaims() {
    return this.authService.identityClaims;
  }

  errorMessage = "";
  file?: File | null;
  data!: any;
  fileName = "";
  uploadMessage = "";
  uploadClicked = "";
  uploadProgress: number = 0;
  uploadSub!: Subscription | null;
  private dataSub!: Subscription;

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    console.log(authService.identityClaims);
  }

  onFileSelected(event: Event) {
    this.resetPage();
    const target = event.target as HTMLInputElement;
    const files = target.files;
    this.file = files?.item(0);
    console.log(this.file);
    if (this.file) {
      this.fileName = this.file.name;
    } else {
      this.fileName = "";
    }
  }

  onButtonClick() {
    console.log("Upload button clicked");
    if (!this.file) return;

    this.resetPage();

    this.uploadClicked = "Upload started";

    console.log(this.fileName);
    const formData = new FormData();

    formData.append("file", this.file, this.file.name);

    this.dataSub = this.postExperianPayersFile(formData).subscribe({
      next: (event: any | AppError) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round((100 * event.loaded) / event.total!);
        } else if (event.type === HttpEventType.Response) {
          this.uploadMessage = "Processing completed successfully.";
        }
      },
      error: (error: AppError) => {
        console.error(
          "ExperianPayersUploadComponent Observer got an error: " +
            JSON.stringify(error)
        );
        this.errorMessage = this.getErrorMessage(error);
        this.dataSub.unsubscribe();
      },
      complete: () => {
        console.log(
          "ExperianPayersUploadComponent Observer got a complete notification"
        );
        this.dataSub.unsubscribe();
      },
    });
  }

  private postExperianPayersFile(formData: FormData) {
    return this.dataService.postExperianPayersFile(formData);
  }

  private getErrorMessage(error: AppError) {
    switch (error.errorNumber) {
      case 400:
        return error.apiErrorMessage;
      case 500:
        return "There was an error processing the file.";
        break;

      default:
        return error.friendlyMessage;
        break;
    }
  }

  private resetPage() {
    this.uploadProgress = 0;
    this.errorMessage = "";
    this.uploadMessage = "";
    this.uploadClicked = "";
  }
}
